.Embeded-container {
    height: 90vh;
    width: 90vw;
    background-color: rgba(255, 255, 255, 0.6); 
    backdrop-filter: blur(10px);
    border-radius: 10px; 
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    padding: 20px; 
  }
  